
import ProductCardHorizontal from '@/components/Products/ProductCardHorizontal.vue'
import ProductCardHorizontalOffer from '@/components/Products/ProductCardHorizontalOffer.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ProductCardHorizontal, ProductCardHorizontalOffer },
  computed: {
    ...mapGetters({
      getPopularProducts: 'popularProducts/getPopularProducts',
    }),
  },
}
